@import '~antd/dist/antd.css';

html {
  font-size: 10px;

  --scrollbar-thumb-padding: 0.6rem;
  --scrollbar-interactive-thumb-padding: 0.2rem;
  --scrollbar-size: 1.4rem;
  --scrollbar-thumb-color: #1422bd;
  --scrollbar-thumb-hover-color: #2633c0;
  --scrollbar-thumb-active-color: #3e4acd;
  --scrollbar-track-color: transparent;
  --scrollbar-track-hover-color: transparent;

  --colors-galery: #f0f0f0;

  background-color: #f4f4f6;
}

@media screen and (max-width: 1350px) {
  html {
    font-size: 9px;
  }
}

*::-webkit-scrollbar {
  width: var(--scrollbar-size);
  height: var(--scrollbar-size);
  background: var(--scrollbar-track-color);
}

*::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color);
}
*::-webkit-scrollbar-track:hover {
  background: var(--scrollbar-track-hover-color);
}

*::-webkit-scrollbar-thumb:vertical {
  border-left: var(--scrollbar-thumb-padding) solid transparent;
  border-right: var(--scrollbar-thumb-padding) solid transparent;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

*::-webkit-scrollbar-thumb:horizontal {
  border-top: var(--scrollbar-thumb-padding) solid transparent;
  border-bottom: var(--scrollbar-thumb-padding) solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--scrollbar-thumb-color);
  background-clip: padding-box;
}

*::-webkit-scrollbar-thumb:hover {
  --scrollbar-thumb-padding: var(--scrollbar-interactive-thumb-padding);

  background: var(--scrollbar-thumb-hover-color);
  background-clip: padding-box !important;
}

*::-webkit-scrollbar-thumb:vertical:active,
*::-webkit-scrollbar-thumb:horizontal:active {
  --scrollbar-thumb-padding: var(--scrollbar-interactive-thumb-padding);

  background: var(--scrollbar-thumb-active-color);
  background-clip: padding-box !important;
}

*::-webkit-scrollbar-corner {
  background: var(--scrollbar-track-color);
}

* {
  scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
  scrollbar-width: thin;
}

*:hover {
  scrollbar-color: var(--scrollbar-thumb-hover-color) var(--scrollbar-track-hover-color);
  scrollbar-width: thin;
}

* {
  font-family: 'Lato', sans-serif;
  padding: 0;
  margin: 0;
}

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  z-index: unset;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

a,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

input,
input:focus {
  outline: none;
}

body {
  margin: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.login .ant-form-item-explain {
  margin-left: 0.5rem;
  font-size: 1rem;
  padding: 0.2rem 0;
}
.login .ant-form-item-explain.ant-form-item-explain-error {
  color: #c61414 !important;
}

/* .ant-checkbox-wrapper .ant-checkbox-inner {
  width: 25px;
  height: 25px;
}
.ant-checkbox-wrapper .ant-checkbox-inner::after {
  transform: rotate(45deg) scale(1.3) translate(-5%, -60%);
}
.ant-checkbox-wrapper span:last-child {
  align-self: center;
  line-height: 30px;
  font-size: 1.5rem;
} */
@tailwind base;
@layer base {
  img {
    @apply inline-block;
  }
}
@tailwind components;
@tailwind utilities;
