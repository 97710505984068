@import '../../variables.scss';

.roomsCalendar {
  height: 100%;
  width: 100%;
  padding-right: 2rem;

  .rbc-toolbar,
  .rbc-show-more,
  .rbc-event-label,
  .rbc-allday-cell,
  .rbc-current-time-indicator {
    display: none;
  }

  .rbc-today {
    background-color: unset;
  }

  .rbc-label {
    font-size: 1.2rem;
    line-height: 2rem;
    font-weight: 600;
  }

  .rbc-time-view {
    border: 0;
  }

  .rbc-time-header .rbc-time-header-gutter + .rbc-time-header-content {
    border-top-left-radius: 0.4rem8px;
    // this way borders are preserved
    overflow: hidden;
  }
  .rbc-time-content .rbc-time-gutter + .rbc-day-slot {
    margin-left: 1px;
    border-left: 1px solid var(--colors-galery);
  }

  .rbc-time-header .rbc-time-header-content:last-child {
    border-right: 1px solid var(--colors-galery);
    border-top-right-radius: 0.4rem8px;
    // this way borders are preserved
    overflow: hidden;
  }
  .rbc-time-content .rbc-day-slot:last-child {
    border-right: 1px solid var(--colors-galery);
  }

  .rbc-header {
    align-items: center;
    display: inline-flex;
    justify-content: center;

    height: 4.2rem;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.4rem;

    background: #f9f9fa;
    border-bottom: 1px solid var(--colors-galery);
  }

  .rbc-time-gutter .rbc-timeslot-group:first-child {
    visibility: hidden;
  }

  .rbc-time-gutter .rbc-time-slot {
    margin-top: -1.1rem;
  }
  .rbc-day-slot .rbc-time-slot {
    border: 0;
  }

  .rbc-time-gutter .rbc-timeslot-group {
    border: 0;
  }
  .rbc-day-slot .rbc-timeslot-group {
    border-bottom: 0;
  }
  .rbc-day-slot .rbc-timeslot-group + .rbc-timeslot-group {
    border-top: 1px dashed var(--colors-galery);
  }
  .rbc-day-slot {
    border-bottom: 1px solid var(--colors-galery);
  }

  .rbc-time-content > * + * > * {
    border-left: 0;
  }

  .rbc-day-slot + .rbc-day-slot,
  .rbc-time-header-content {
    border-left: 1px solid var(--colors-galery);
  }

  .rbc-time-view-resources .rbc-time-header-gutter {
    margin-right: 0;
  }

  .rbc-time-header-content {
    border-top: 1px solid var(--colors-galery);
  }

  .rbc-day-slot .rbc-events-container {
    border: 0;
  }

  .rbc-time-content > .rbc-day-slot,
  .rbc-time-view-resources .rbc-time-header-content {
    min-width: 20rem;
  }

  .rbc-time-view-resources .rbc-time-header-gutter,
  .rbc-time-view-resources .rbc-time-gutter {
    border-right: 0;
  }

  .rbc-time-header-content > .rbc-row.rbc-row-resource {
    border-bottom: 0;
  }

  .rbc-day-slot .rbc-event {
    border: 0;
    min-height: 2.5rem;
    // height: unset !important;
  }

  .rbc-time-content {
    border-top: 0;
  }

  .rbc-day-slot .rbc-event.rbc-selected {
    box-shadow: 0rem 0.15rem 0.9rem currentColor;
    z-index: 20;
  }

  .rbc-event,
  .rbc-event-label {
    font-size: 1.6rem;
    font-weight: 700;
  }

  .rbc-day-slot .rbc-event-label,
  .rbc-day-slot .rbc-event-content {
    padding: 0 0rem;
    line-height: unset;
  }

  .rbc-timeslot-group {
    min-height: clamp(6rem, 6vh, 10rem);
  }

  .rbc-event-content-label {
    display: inline-flex;
    align-items: center;
  }
}

.displayEventsInOneColumn {
  .rbc-day-slot .rbc-event {
    width: 100% !important;
    left: 0% !important;
  }
}

.roboticCase {
  background: $chartColorRobotic !important;
  border-left: 4px solid $chartTextColorRobotic !important;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 0.4rem;
  &:hover {
    background: $chartColorRoboticHover !important;
  }
}

.openCase {
  border-radius: 0.4rem;
  background: $chartColorOpen !important;
  border-left: 4px solid $chartTextColorOpen !important;
  padding: 0 !important;
  margin: 0 !important;
  &:hover {
    background: $chartColorOpenHover !important;
  }
}

.lapCase {
  border-radius: 0.4rem;
  background: $chartColorLap !important;
  border-left: 4px solid $chartTextColorLap !important;
  padding: 0 !important;
  margin: 0 !important;
  &:hover {
    background: $chartColorLapHover !important;
  }
}

.loading-room-event {
  background: var(--colors-galery) !important;
  border-left: 4px solid $neutralGray !important;
}
