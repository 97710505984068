@import '../../variables.scss';

.datepickerContainer {
  .react-datepicker-wrapper {
    width: unset;
  }

  .react-datepicker__month-container {
    padding: 2.4rem;
  }

  .react-datepicker-popper {
    z-index: 1000;
  }

  .react-datepicker {
    font-size: 2.1rem;

    @include for-size(xs) {
      font-size: 1.5rem;
    }

    background: #ffffff;
    box-shadow: 0rem 1.2rem 2.6rem rgb(16 30 115 / 6%);
    border-radius: 0.8rem;
    border: 0;
  }

  .react-datepicker__header {
    padding-top: 0;
    border-bottom: 0;
    padding-bottom: 0;
    background: $white;
  }

  .react-datepicker__day-names {
    margin-top: 1.6rem;
    background: #ffffff;
  }

  .react-datepicker__day-name {
    color: #7c879d;
  }

  .react-datepicker__month {
    margin: 0;
    margin-top: 0.8rem;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    font-size: 1.2rem;
    line-height: 1.4em;
    width: 4rem;
    height: 4rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__current-month {
    font-size: 1em;
  }

  .react-datepicker__day.react-datepicker__day--selected,
  .react-datepicker__day.react-datepicker__day--selected:hover {
    background-color: $persianBlue;
  }

  .react-datepicker__week .react-datepicker__day {
    border-radius: 5rem;
  }

  .react-datepicker__day:hover {
    background: $peranoHover;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: unset;
    color: unset;
  }

  .react-datepicker__day--keyboard-selected:hover {
    background-color: #f0f0f0;
  }

  .react-datepicker__day--outside-month {
    color: $disabled;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    margin: 0;
  }

  .react-datepicker__navigation {
    position: relative;
  }

  .react-datepicker__triangle {
    visibility: hidden;
  }
}

.monthpickerContainer {
  .react-datepicker {
    font-size: 2.1rem;

    @include for-size(xs) {
      font-size: 1.5rem;
    }

    line-height: 2.1em;
    background: #ffffff;
    box-shadow: 0rem 1.2rem 2.6rem rgb(16 30 115 / 6%);
    border-radius: 0.8rem;
    border: 0;
  }

  .react-datepicker {
    padding: 1.6rem;
  }

  .react-datepicker-wrapper {
    width: unset;
  }

  .react-datepicker-popper {
    z-index: 1000;
  }

  .react-datepicker__header {
    padding-top: 0;
    padding-bottom: 0.8rem;
    margin-bottom: 1.6rem;
    background: $white;
    border-bottom: 1px solid #f0f0f0;
  }

  .react-datepicker__navigation {
    position: relative;
  }

  .react-datepicker__month-wrapper {
    padding: 0;
    display: flex;
  }

  .react-datepicker__month .react-datepicker__month-text {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 4rem;
    height: 4rem;
    font-size: 1.2rem;
    line-height: 1.4rem;
    border-radius: 5rem;
  }

  .react-datepicker__month .react-datepicker__month-text:hover {
    background: $peranoHover;
  }

  .react-datepicker__month-text--keyboard-selected {
    background-color: unset;
    color: unset;
  }

  .react-datepicker__month-text--keyboard-selected:hover {
    background-color: #f0f0f0;
  }

  .react-datepicker__month .react-datepicker__month--selected,
  .react-datepicker__month .react-datepicker__month--selected:hover {
    background-color: $persianBlue;
    color: $white;
  }

  .react-datepicker__month--disabled {
    color: $disabled;
  }

  .react-datepicker__triangle {
    visibility: hidden;
  }
}
