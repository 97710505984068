@import '../../variables.scss';

.bubbleContainer {
  height: calc(100vh - 27rem);
  width: 100%;

  @include for-size(md) {
    height: 60rem;
  }

  @include for-size(xs) {
    height: 45rem;
  }

  .rbc-toolbar,
  .rbc-show-more {
    display: none;
  }

  .rbc-row-content {
    z-index: unset;

    .rbc-row:nth-child(2) {
      flex-grow: 1;
    }
  }

  .rbc-row-content {
    display: flex;
    flex-direction: column;
  }

  .rbc-row-content,
  .rbc-row-segment,
  .rbc-event-content {
    height: 100%;
    padding: 0;
  }

  .rbc-event-content {
    width: 100%;
    display: flex !important;
    align-items: flex-start;
    justify-content: center;
  }

  .rbc-event {
    padding: 0;
    height: 100%;
    cursor: default;
    background-color: unset !important;
  }

  .rbc-month-view {
    border: 1px solid var(--colors-galery);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    // this way borders are preserved
    overflow: hidden;
  }

  .rbc-month-row + .rbc-month-row {
    border-top: 1px solid var(--colors-galery);
  }
  .rbc-header + .rbc-header,
  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid var(--colors-galery);
  }

  .rbc-month-header .rbc-header {
    height: 4rem;
    align-items: center;
    display: inline-flex;
    background: #f9f9fa;
    text-transform: uppercase;
    justify-content: flex-start;
    border-bottom: 1px solid var(--colors-galery);
  }

  .rbc-header {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 2rem;
    text-align: right;
    padding-left: 1.5rem;
  }

  .rbc-date-cell:not(.show) {
    display: none;
  }
  .rbc-date-cell {
    font-size: 1.7rem;
    font-weight: 700;
    padding-top: 1rem;
    padding-right: 1rem;
  }

  .rbc-off-range-bg {
    background: initial;
  }

  .rbc-off-range {
    opacity: 0.3;
    color: #25282b;
  }

  .rbc-event,
  .rbc-event.rbc-selected {
    outline: none !important;
  }
}
