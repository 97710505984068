@import '../../variables.scss';

.ChartToltip {
  width: auto;
  background: $white;
  border-radius: 5px;
  box-shadow: 0px 2px 4px silver;
  position: relative;
  bottom: 20rem;
  left: -50%;
  padding: 16px;

  .tooltip_date {
    display: flex;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 26px;
    letter-spacing: 0.1px;
    color: #cacccf;

    img {
      margin-right: 6px;
    }
  }

  .tooltip-triangle {
    position: absolute;
    bottom: -4px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 11;
  }

  .tooltip_list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 26px;
    letter-spacing: 0.1px;
    color: #25282b;
  }

  .tooltip_list_item {
    display: flex;
    align-items: center;
  }

  .tooltip_list_item-line {
    height: 6px;
    width: 60px;
    background-color: #daa6ae;
    margin-right: 12px;
    border-radius: 6px;
  }
}
