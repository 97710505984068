@import '../../variables.scss';
.MultiLevelSelectbox {
  ul.ant-menu {
    border-radius: 0.8rem;
    padding: 0.5rem;

    .selected {
      background-color: rgba($perano, 0.2);
    }

    li.ant-menu-submenu-disabled, li.ant-menu-item-disabled {
      background-color: #fff;
      opacity: 0.5;
      color: #11142d;
      &.selected {
        background-color: #fff;
      }
    }
    li.ant-menu-submenu,
    li.ant-menu-sub,
    li.ant-menu-item {
      padding: 0;
      margin: 0;
      padding: 0 0.5rem;
      margin-top: 0.5rem;
      margin-bottom: 0;
      height: 3.2rem;
      border-radius: 0.4rem;
      &:hover {
        background-color: #f8f8f8;
        color: #11142d;
      }

      div.ant-menu-submenu-title,
      .ant-menu-title-content {
        &:active {
          background-color: transparent;
        }
        display: flex;
        width: 100%;
        align-items: center;
        height: 3.2rem;
        line-height: 2rem;
        padding: 0;
        margin: 0;
        color: #11142d;
        font-weight: 600;

        font-size: 1.2rem;
        font-family: Lato, sans-serif;
      }
      i.ant-menu-submenu-arrow {
        display: none;
      }
    }
  }
}
