@import '../../variables.scss';

.ora-table {
  & tr:hover > td > .ora-table-td {
    background-color: $peranoHover;
  }

  & tr > td {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  & tr > td:first-child {
    padding-left: 8px;
  }

  & tr > td:first-child .ora-table-td {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  & tr > td:last-child {
    padding-right: 8px;
  }
  & tr > td:last-child .ora-table-td {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
