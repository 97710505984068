$neutralBlack: #25282b;
$neutralGray: #a0a4a8;
$greyBg: #e1e1e1;
$blue: #336cfb;
$persianBlue: #1422bd;
$perano: #a5bef5;
$peranoHover: #a5bef533;
$lavender: #e5f4fc;
$cornflowerBlue: #606062;
$ghost: #c8ced9;
$ghostBorder: #c8ced94d;
$regentGrey: #7c889d;
$athensGrey: #f4f4f6;
$headerGrey: #f9f9fa;
$headerTextGrey: #7c879d;
$tableEvenRow: #fbfbfc;
$midGrey: #606062;
$galery: var(--colors-galery);
$venetianRed: #c61414;
$unbleachedSilk: #fcd9ca;
$oldLace: #fcece5;
$lavenderBlue: #cacafc;
$selago: #e5e5fc;
$cultured: #f4f4f6;
$water: #caeafc;
$black: #11142d;
$grey: #8a92a6;
$silver: #9a9ab0;
$neutralBlack: #25282b;

$chartColorRobotic: rgb(202 202 252 / 0.5);
$chartColorRoboticHover: rgb(202 202 252 / 0.8);
$chartColorOpen: rgb(202 234 252 / 0.5);
$chartColorOpenHover: rgb(202 234 252 / 0.8);
$chartColorLap: rgb(252 217 202 / 0.5);
$chartColorLapHover: rgb(252 217 202 / 0.8);
$chartTextColorRobotic: #9797fc;
$chartTextColorOpen: #97d8fc;
$chartTextColorLap: #fcb597;

$darkBlue: #232d42;
$persianBlue: #1422bd;
$silverBlue: #8a92a6;
$white: #fff;
$orange: #fac032;
$link: #707070;
$grey2: #ebeff2;
$grey: #25282b;
$error: #ff4d4f;
$darkGrey: #585858;
$active: #488048;
$bgActive: #e2f5e2;
$bgInactive: #fbebeb;
$inactive: #bd4a4a;
$peranoHover: #a5bef533;
$disabled: #bebfc0;

$caseStageSurgery: #fff8b5;
$caseStageSurgeryBorder: #fff490;

$caseStagePrep: #b5e4ff;
$caseStagePrepBorder: #90d7ff;

$caseStageTurnover: #ffd8b5;
$caseStageTurnoverBorder: #ffc18a;

$goodColor: #27ae60;
$badColor: #c61414;

$caseStagePost: rgba(20, 189, 88, 0.2);
$caseStagePostBorder: #0f9d48;

$caseStageSurgerySelected: #feedc2;
$caseStagePrepSelected: #c3d0f1;
$caseStageTurnoverSelected: #f2d9bc;
$caseStagePostSelected: #e8ced2;
$green: green;
$errorAuth: #eb5757;
$menuIconColor: #9a9ab0;
$menuActiveIconColor: #fff;

@mixin for-size($size) {
  @if $size ==xs {
    @media (max-width: 450px) {
      @content;
    }
  } @else if $size ==sm {
    @media (min-width: 640px) {
      @content;
    }
  } @else if $size ==md {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $size ==lg {
    @media (min-width: 1024px) {
      @content;
    }
  } @else if $size ==xl {
    @media (min-width: 1280px) {
      @content;
    }
  } @else if $size == '2xl' {
    @media (min-width: 1536px) {
      @content;
    }
  }
}
