.stopwatch-container {
  path {
    transition: all 0.3s ease-in-out;
    transform-origin: 50% 50%;
  }
  .pattern {
    transition: all 0.3s ease;
    transform: scale(1, 1);
    transform-origin: 50% 50%;
  }
  &:hover {
    .pattern {
      transition: all 0.3s ease;
      transform: scale(2, 2) rotate(90deg);
      transform-origin: 50% 50%;
    }
    .stopwatch-big-arrow {
      transform: rotate(180deg) translate(0, -5px);
      transform-origin: 50% 50%;
    }
    .stopwatch-small-arrow {
      transform: rotate(30deg);
      transform-origin: 50% 50%;
    }
    .stopwatch-buttons {
      transform: scale(1.1, 1.1) translate(1px, 3px);
      transform-origin: 50% 50%;
    }
  }
}

.triangle-circles-container {
  circle {
    transition: all 0.3s ease-in-out;
    transform-origin: 50% 50%;
  }
  .pattern {
    transition: all 0.3s ease;
    transform: scale(1, 1);
    transform-origin: 50% 50%;
  }
  &:hover {
    .pattern {
      transition: all 0.3s ease;
      transform: scale(2, 2) rotate(115deg);
      transform-origin: 50% 50%;
    }
    .triangle-circles-left {
      transform: scale(0.5, 0.5) translate(8px, -40px);
      stroke-width: 8px;
      transform-origin: 50% 50%;
    }
    .triangle-circles-right {
      transform: scale(0.5, 0.5) translate(-40px, 20px);
      stroke-width: 8px;
      transform-origin: 50% 50%;
    }
    .triangle-circles-top {
      transform: scale(0.5, 0.5) translate(30px, 36px);
      stroke-width: 8px;
      transform-origin: 50% 50%;
    }
  }
}
.bars-container {
  path, rect {
    transition: all 0.3s ease-in-out;
    transform-origin: 50% 50%;
  }
  .pattern {
    transition: all 0.3s ease;
    transform: scale(1, 1) rotate(0deg);
    transform-origin: 50% 50%;
  }
  &:hover {
    .pattern {
      transition: all 0.3s ease;
      transform: scale(2, 2) rotate(90deg);
      transform-origin: 50% 50%;
    }
    .bars-arrow {
      transform: rotate(60deg) translate(-2px, -2px);
      transform-origin: 50% 50%;
    }
    .bars-left-outer-bar {
      height: 40px;
      transition: all 0.3s ease;
      transform:  translate(0px, -17px);
      transform-origin: bottom;
    }
    .bars-left-inner-bar {
      height: 33px;
      transition: all 0.3s ease;
      transform:  translate(0px, -17px);
      transform-origin: bottom;
    }
    .bars-right-outer-bar {
      height: 22px;
      transition: all 0.3s ease;
      transform:  translate(0px, 20px);
      transform-origin: bottom;
    }
    .bars-right-inner-bar {
      height: 15px;
      transition: all 0.3s ease;
      transform:  translate(0px, 20px);
      transform-origin: bottom;
    }
  }
}
.horizontal-bars-container {
  path, rect {
    transition: all 0.3s linear;
  }
  .pattern {
    transition: all 0.3s ease;
    transform: scale(1, 1);
    transform-origin: 50% 50%;
  }
  &:hover {
    .pattern {
      transition: all 0.3s ease;
      transform: scale(2, 2) rotate(90deg);
      transform-origin: 50% 50%;
    }
    .horizontal-bars-top-point {
      transform: translate(0px, -3px);
      transform-origin: left;
    }
    .horizontal-bars-bottom-point {
      transform: translate(3px, 0px);
      transform-origin: left;
    }
    .horizontal-bars-top-outer-bar {
      width: 28px;
    }
    .horizontal-bars-top-inner-bar {
      width: 24px;
    }
    .horizontal-bars-middle-outer-bar {
      width: 17px;
    }
    .horizontal-bars-middle-inner-bar {
      width: 13px;
    }
    .horizontal-bars-bottom-outer-bar {
      width: 49px;
    }
    .horizontal-bars-bottom-inner-bar {
      width: 45px;
    }
  }
}

.people-container {
  path {
    transition: all 0.3s linear;
  }
  .pattern {
    transition: all 0.3s ease;
    transform: scale(1, 1);
    transform-origin: 50% 50%;
  }
  &:hover {
    .people-left {
      transform: translate(-2px, -2px);
      transform-origin: left;
    }
    .people-right {
      transform: translate(2px, -2px);
      transform-origin: left;
    }
    .people-center {
      transform: translate(0px, -2px);
      transform-origin: left;
    }
    .pattern {
      transition: all 0.3s ease;
      transform: scale(2, 2);
      transform-origin: 50% 50%;
    }
  }
}
