@import '../../variables.scss';

.Auth {
  height: 100vh;
  display: flex;

  .loadingButton {
    height: 3rem;
  }

  .error {
    color: $errorAuth;
    font-size: 1.4rem;
    font-weight: 400;
    text-align: center;
  }

  .success {
    color: $green;
    font-size: 1.4rem;
    font-weight: 400;
    text-align: center;
  }

  .ant-input-prefix svg {
    font-size: 2.5rem;
    opacity: 0.6;
    margin-right: 0.9rem;
  }

  .ant-form {
    margin-top: 0.6rem;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-input-affix-wrapper .ant-input {
    background: transparent;
  }

  .ant-form-item-explain-error,
  .ant-form-item-explain-success {
    margin-top: -1.5rem;
  }

  .title {
    font-size: 4.8rem;
    white-space: nowrap;
    color: inherit;
    font-weight: 900;
    line-height: 4.8rem;
    margin-bottom: 0.9rem;

    &_black {
      color: #333333;
      text-align: center;
    }
  }

  .redirect {
    font-size: 1.4rem;
    font-family: 'Poppins', sans-serif;
    color: #333333;
    text-align: center;
    cursor: pointer;
  }

  .description {
    font-size: 1.8rem;
    color: inherit;
    font-weight: 700;
    margin-bottom: 3.2rem;

    &_black {
      color: #333333;
      text-align: center;
    }
  }

  .left-side {
    flex: 0 0 55%;
    padding-left: 5%;
    padding-top: 7rem;
    color: white;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-position: left;

    .title {
      margin-bottom: 0.5rem;
    }
  }

  .right-side {
    background-color: white;
    flex: 0 0 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 11% 0 12%;
  }

  .security {
    width: 343px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    color: #333333;
  }

  .contact-support {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: $link;
    text-align: center;
    width: 8.2rem;
    margin: 0 auto;
    margin-top: 54px;
    border-bottom: 1px solid $link;
    cursor: pointer;
  }
}
