@import '../../variables';

.date-picker {
  width: 170px;
  height: 25px;
  padding: 0;
  border: 0;
  line-height: 25px;
  padding-left: 10px;
  font-size: 12px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  cursor: pointer;
  color: #303030;
  position: relative;
  z-index: 2;
}

.date-picker-wrapper {
  position: absolute;
  z-index: 1;
  border: 1px solid #F0F0F0;
  background-color: white;
  padding: 5px 12px;
  font-size: 12px;
  line-height: 20px;
  color: #aaa;
  font-family: Arial, sans-serif;
  box-shadow: 0px 5px 10px rgba(28, 28, 31, 0.05);
  box-sizing: border-box;
  border-radius: 8px;
}

.dp-clearfix {
  display: none;
  clear: both;
  height: 0;
  font-size: 0;
}

.date-picker-wrapper {
  &.inline-wrapper {
    position: relative;
    box-shadow: none;
    display: inline-block;
  }

  &.single-date {
    width: auto;
  }

  &.no-shortcuts {
    padding-bottom: 12px;
  }

  &.no-topbar {
    padding-top: 12px;
  }


  .footer {
    form {
      display: flex;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      margin-bottom: 1rem;
      justify-content: space-between;
      border-top-width: 1px;
      padding-top: 2.4rem;
    }

    .divider-container {
      display: flex;
      margin-left: 1rem;
      margin-right: 1rem;
      flex-direction: column;
      width: 1.8rem;


      .divider {
        height: 50%;
        border-bottom: 1px solid #616163;
      }
    }

    .buttons-container {
      display: flex;
      justify-content: space-around;
      width: 50%;
    }

    .footer-button {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      font-weight: 700;
      letter-spacing: 0.05em;
      font-size: 1.2rem;
      line-height: 1.4rem;
      width: 14rem;
      height: 3.2rem;

      border-radius: 0.8rem;

      &.cancel {
        background: $white;
        color: $black;
        border: 1px solid $galery;
      }

      &.apply {
        background-color: $black;
        color: $white
      }
    }

    .footer-date-input {
      border: 1px solid $galery;
      display: flex;
      align-items: center;
      width: 12.9rem;
      height: 32px;
      border-radius: 0.6rem;

      & .icon {
        padding: 0.9rem;

        & svg {
          fill: $black
        }
      }

      input {
        padding-left: 0;
        padding-right: 0;
        color: #1c1c1f;
        font-weight: 600;
        letter-spacing: 0.05em;
        width: 100%;
        border-style: none;
        box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);

        font-size: 1.2rem;

        &::placeholder {
          font-family: Lato, sans-serif;
          font-size: 1.2rem;
          color: #1c1c1f44;
          caret-color: $persianBlue;
          font-weight: 600;
          letter-spacing: 0.05em;
        }

        .focus\:ring-0:focus {
          --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
          --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
          box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
        }
      }
    }
  }

  b {
    color: #666;
    font-weight: 700;
  }

  a {
    color: rgb(107, 180, 214);
    text-decoration: underline;
  }

  .month-hear-header {
    width: 100%;
  }

  .month-name {
    text-transform: capitalize;
    text-align: start;
  }

  .controls-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .month-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .select-wrapper {
    overflow: hidden;
    display: inline-block;
    margin-top: .1rem;

    & .month-text {
      text-align: center;
      padding: 0 1.1rem;
      width: 9.5rem;
      display: block;
      text-transform: capitalize;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: $persianBlue;
      cursor: pointer;
    }

  }

  .month-element {
    display: inline-block;
    vertical-align: middle;
  }

  .select-wrapper select {
    width: 100%;
    position: absolute;
    margin: 0;
    padding: 0;
    left: 0;
    top: 2px;
    height: 2rem;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    text-transform: inherit;
    color: black;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: 0;
    outline: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
    filter: alpha(opacity=1);
    opacity: 0.01;
  }

  .select-container-head {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
    transition-duration: 300ms !important;
    user-select: none !important;
    border: 1px solid $galery;
    color: $black;
    background-color: $white;

    align-items: center;
    position: relative;
    cursor: pointer;
    border-radius: 0.8rem
  }

  .select-head {

    @media (min-width: 768px) {
      font-size: 1.2rem;
      min-width: 8.2rem;
      height: 3rem;
    }

    min-width: 8rem;
    height: 2.8rem;
    font-size: 1rem;
    padding-left: 1.6rem;
    padding-right: 2.7rem;
    text-align: 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-style: normal;
    font-weight: 600;
    position: relative;
  }

  .chevron-icon-styles {
    --tw-rotate: -90deg !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
    display: block;
    position: absolute;
    right: 0.8rem;
    cursor: pointer;
    margin-top: 0.1rem;

    @media (min-width: 1024px) {
      right: 1.6rem;
    }

    @media (min-width: 768px) {
      top: 1.1rem;
      right: 1rem;
    }
  }


  .select-month {
    .select-options-container {
      display: none;
      position: absolute;
      margin: 0;
      margin-top: 0.75rem;
      border-style: solid;
      padding: 1rem;
      z-index: 1000;
      background-color: $white;
      border-radius: 0.8rem;
      --tw-border-opacity: 1 !important;
      border: 1px solid rgb(235 239 242 / var(--tw-border-opacity));
    }

    &.select-opened .select-options-container {
      display: block;
    }

    .select-list {
      display: flex;
      flex-wrap: wrap;
      width: 16rem;
      height: 12rem;
    }

    .select-option {
      display: inline-flex;
      color: #000000;
      justify-content: center;
      align-items: center;
      border-radius: 9999px;
      cursor: pointer;
      width: 4rem;
      height: 4rem;

      &.selected {
        background-color: $persianBlue;
        color: $white;
      }

      &:hover {
        background-color: rgba(165, 190, 245, 0.2);
      }

      &:active {
        background-color: $persianBlue;
        color: $white;
      }
    }
  }

  .select-year {
    .select-options-container {
      display: none;
      padding-top: 0;
      margin: 0;
      margin-top: 0.75rem;
      --tw-border-opacity: 1 !important;
      border: 1px solid rgb(235 239 242 / var(--tw-border-opacity));
      z-index: 1000;
      position: absolute;
      background: $white;
      border-radius: 0.8rem;
    }

    &.select-opened .select-options-container {
      display: block;
    }

    .select-list {
      overflow-y: auto;
      margin: 0;
      list-style-type: none;
      max-height: 20rem;
    }

    .select-option {
      &:hover {
        --tw-bg-opacity: 1;
        background-color: rgb(248 248 248 / var(--tw-bg-opacity));

        &.selected {
          background-color: $persianBlue;
          color: $white;
        }
      }

      &.selected {
        background-color: $persianBlue;
        color: $white;
      }

      font-family: Lato,
      sans-serif;
      margin: 0.6rem auto;
      color: $black;
      font-weight: 600;
      line-height: 2rem;
      font-style: normal;
      font-size: 1.2rem;
      position: relative;
      padding: 0.4rem 1.9rem;
      border-top-right-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
      cursor: pointer;

      @media (min-width: 768px) {
        width: auto;
        font-size: 1.2rem;
      }

      @media (min-width: 1024px) {
        font-size: 1.2rem;
      }
    }
  }

  .month-wrapper {
    width: 646px !important;
    border-radius: 3px;
    background-color: #fff;
    padding: 5px 10px;
    padding-top: 19px;
    display: flex;
    justify-content: space-between;
    cursor: default;
    position: relative;
    _overflow: hidden;

    table {
      position: relative;
      width: 300px;

      &.month2 {
        width: 300px;
      }

      th,
      td {
        vertical-align: middle;
        text-align: center;
        line-height: 14px;
        margin: 0px;
        padding: 0px;
      }

      .day {
        position: relative;
        border-radius: 50%;
        width: 4rem;
        height: 4rem;
        padding: 14px 0;
        line-height: 14px;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 1px;
        color: #ccc;
        cursor: default;
      }

      .day-cell {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .day-placeholder {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 1;
      }

      div.day {

        &.lastMonth,
        &.nextMonth {
          color: #999;
          cursor: default;
        }
      }

      .day.checked .day-placeholder {
        background-color: rgba(165, 190, 245, 0.2);
        border-radius: 0px;
      }

      .week-name {
        height: 20px;
        font-size: 1.3rem;
        line-height: 1.6rem;
        font-weight: 100;
        text-transform: capitalize;
        color: #7C879D;

        & th {

          padding-bottom: 1rem;
        }
      }

      .day.has-tooltip {
        cursor: help !important;

        .tooltip {
          white-space: nowrap;
        }
      }
    }
  }

  .time label {
    white-space: nowrap;
  }

  .month-wrapper table .day {
    &.toMonth {
      &.valid {
        color: #333;
        cursor: pointer;
      }

      &.hovering {
        & .day-placeholder {
          border-radius: 0px;
          background-color: rgba(165, 190, 245, 0.2);
        }

        &.before-first-selected .day-placeholder {
          border-top-left-radius: 50%;
          border-bottom-left-radius: 50%;
        }

        &.after-first-selected .day-placeholder {
          border-top-right-radius: 50%;
          border-bottom-right-radius: 50%;
        }
      }

    }

    &.nextMonth,
    &.lastMonth {
      display: none;
    }

    &.real-today {
      & .day-cell {
        background-color: #1C1C1F;
        color: white;
        border-radius: 50%;
      }

    }
  }

  table .caption {
    height: 40px;

    >th {

      &:first-of-type,
      &:last-of-type {
        width: 27px;
      }
    }

    .next,
    .prev {
      border-radius: 7px;
      display: block;
      padding: 1rem;
      cursor: pointer;
    }

    .next-container {
      width: 2.6rem;
      height: 2.6rem;
    }

    .next {
      transform: rotate(180deg);
    }

    .next:hover,
    .prev:hover {
      background-color: #ccc;
      color: white;
    }
  }

  .gap {
    position: relative;
    z-index: 1;
    width: 15px;
    height: 100%;
    font-size: 0;
    line-height: 0;
    float: left;
    top: -5px;
    margin: 0 10px -10px;
    // visibility: hidden;
    display: none;
    height: 0;

    .gap-lines {
      height: 100%;
      overflow: hidden;
    }

    .gap-line {
      height: 15px;
      width: 15px;
      position: relative;

      .gap-1 {
        z-index: 1;
        height: 0;
        border-left: 8px solid white;
        border-top: 8px solid #eee;
        border-bottom: 8px solid #eee;
      }

      .gap-2 {
        position: absolute;
        right: 0;
        top: 0px;
        z-index: 2;
        height: 0;
        border-left: 8px solid transparent;
        border-top: 8px solid white;
      }

      .gap-3 {
        position: absolute;
        right: 0;
        top: 8px;
        z-index: 2;
        height: 0;
        border-left: 8px solid transparent;
        border-bottom: 8px solid white;
      }
    }

    .gap-top-mask {
      width: 6px;
      height: 1px;
      position: absolute;
      top: -1px;
      left: 1px;
      background-color: #eee;
      z-index: 3;
    }

    .gap-bottom-mask {
      width: 6px;
      height: 1px;
      position: absolute;
      bottom: -1px;
      left: 7px;
      background-color: #eee;
      z-index: 3;
    }
  }

  .selected-days {
    display: none;
  }

  .drp_top-bar {
    display: none;
    line-height: 1.4;
    position: relative;
    padding: 10px 40px 10px 0;

    .error-top,
    .normal-top {
      display: none;
    }

    .default-top {
      display: block;
    }

    &.error {
      .default-top {
        display: none;
      }

      .error-top {
        display: block;
        color: red;
      }
    }

    &.normal {
      .default-top {
        display: none;
      }

      .normal-top {
        display: block;

        .selection-top {
          color: #333;
        }
      }
    }

    .apply-btn {
      position: absolute;
      right: 0px;
      top: 6px;
      padding: 3px 5px;
      margin: 0;
      font-size: 12px;
      border-radius: 4px;
      cursor: pointer;
      color: #d9eef7;
      border: solid 1px #0076a3;
      background: #0095cd;
      background: -webkit-gradient(linear, left top, left bottom, from(#00adee), to(#0078a5));
      background: -moz-linear-gradient(top, #00adee, #0078a5);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00adee', endColorstr='#0078a5');
      color: white;
      line-height: initial;

      &.disabled {
        cursor: pointer;
        color: #606060;
        border: solid 1px #b7b7b7;
        background: #fff;
        background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#ededed));
        background: -moz-linear-gradient(top, #fff, #ededed);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ededed');
      }
    }
  }

  .time {
    display: none;
    position: relative;
  }

  &.single-month .time {
    display: block;
  }

  .time input[type=range] {
    vertical-align: middle;
    width: 129px;
    padding: 0;
    margin: 0;
    height: 20px;
  }

  .time1 {
    width: 180px;
    padding: 0 5px;
    text-align: center;
  }
}

/*time styling*/

.time2 {
  width: 180px;
  padding: 0 5px;
  text-align: center;
}

.date-picker-wrapper {
  .time1 {
    float: left;
  }

  .time2 {
    float: right;
  }

  .hour {
    text-align: right;
  }
}

.minute {
  text-align: right;
}

.date-picker-wrapper {
  .hide {
    display: none;
  }

  .month-wrapper {

    .first-date-selected,
    .last-date-selected {
      & .day-cell {
        background-color: #1422BD;
        color: white;
        border-radius: 50%;
      }
    }

    .day.checked.first-date-selected {
      & .day-placeholder {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
        border-top-right-radius: 0%;
        border-bottom-right-radius: 0%;
      }
    }

    .day.checked.last-date-selected {
      & .day-placeholder {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        border-top-left-radius: 0%;
        border-bottom-left-radius: 0%;
      }
    }

    .day.checked.last-date-selected.has-after-hovering {
      & .day-placeholder {
        border-top-right-radius: 0%;
        border-bottom-right-radius: 0%;
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }
    }

    .day.checked.first-date-selected.has-before-hovering {
      & .day-placeholder {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        border-top-left-radius: 0%;
        border-bottom-left-radius: 0%;
      }
    }

    .day.checked.first-date-selected.only {
      & .day-placeholder {
        border-radius: 50%;
      }
    }

    table .day.start-of-week,
    table .day.start-of-month {
      & .day-placeholder {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }

      &.hovering {
        & .day-placeholder {
          border-top-left-radius: 50%;
          border-bottom-left-radius: 50%;
        }
      }
    }

    table .day.end-of-week,
    table .day.end-of-month {
      & .day-placeholder {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }

      &.hovering {
        & .day-placeholder {
          border-top-right-radius: 50%;
          border-bottom-right-radius: 50%;
        }
      }
    }
  }

  .date-range-length-tip {
    position: absolute;
    margin-top: -4px;
    margin-left: -8px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    display: none;
    color: white;
    background-color: black;
    padding: 0 6px;
    border-radius: 2px;
    font-size: 12px;
    line-height: 16px;
    -webkit-filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
    -moz-filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
    -ms-filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
    -o-filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));

    &:after {
      content: '';
      position: absolute;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid black;
      left: 50%;
      margin-left: -4px;
      bottom: -4px;
    }
  }

  &.two-months.no-gap {

    .month1 .next,
    .month2 .prev {
      opacity: 0.5;
      cursor: default;

      &:hover {
        background-color: transparent;
      }

      // display: none;
    }
  }

  .week-number {
    padding: 5px 0;
    line-height: 1;
    font-size: 12px;
    margin-bottom: 1px;
    color: #999;
    cursor: pointer;

    &.week-number-selected {
      color: #49e;
      font-weight: bold;
    }
  }
}