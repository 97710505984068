@import '../../variables.scss';

.ora-pagination {
  display: flex;
  li.ant-pagination-item.ant-pagination-item-active {
    &:hover {
      a {
        color: white;
      }
    }
    border: 1px solid $black;
    background: $black;
    a {
      color: white;
    }
  }
  li.ant-pagination-prev {
    display: flex;
    justify-content: start;
    padding-left: 0.5rem;
    border: 1px solid transparent;
  }

  li.ant-pagination-next {
    display: flex;
    justify-content: end;
    padding-right: 0.6rem;
    padding-left: 0.5rem;
    border: 1px solid transparent;
  }
  li.ant-pagination-item,
  li.ant-pagination-prev,
  li.ant-pagination-next {
    &:hover {
      border: 1px solid $black;
      a {
        color: $black;
      }
    }

    border-radius: 0.8rem;
    min-width: 3rem;
    height: 3rem;
    a {
      font-weight: 600;
      color: $black;
      padding: 0;
      font-size: 1.4rem;
      font-family: Lato, sans-serif;
    }
  }
  li.ant-pagination-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  li.ant-pagination-next.ant-pagination-disabled,
  li.ant-pagination-prev.ant-pagination-disabled {
    border: 1px solid transparent;
    &:hover {
      border: 1px solid transparent;
    }
  }
}
