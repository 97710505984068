@import '../../variables.scss';

.ant-tooltip .ant-tooltip-content {
  border-radius: 8px;
  .ant-tooltip-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 64px;
    padding: 0.2rem 1rem;
    min-width: auto;
    min-height: auto;
    font-weight: 600;
    font-size: 1.2rem;
    font-family: Lato, sans-serif;
    background: rgba(0, 0, 0, 0.9);
  }
  .ant-tooltip-arrow-content {
    background: rgba(0, 0, 0, 0.9);
  }
}
